import './App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF, faInstagram, faLinkedin, faTwitter, faBehance, faGithub } from '@fortawesome/free-brands-svg-icons';
import React, { useState } from 'react';
import ShayelCV from './assets/ShayelCV.pdf'

const LinkIcon = ({ url, icon, text, fadeIcon, inSite }) => <a href={url} target={inSite? "_self" : "_blank"} ><div className={fadeIcon ? 'circle fade-icon' : 'circle'}>
  {text || <FontAwesomeIcon icon={icon} />}
</div></a>

function App() {
  const [hoverTitle, setHoverTitle] = useState(null);
  return (
    <div className="main-container">
      <div className='photo-container'/>
      <div className='info-container'>
        <div className='content'>
          <h1 >Shayel Magnezi</h1>
          <div className='titles'>
            <span onMouseEnter={()=>{setHoverTitle('Engineer')}} onMouseLeave={()=>{setHoverTitle(null)}}>Engineer </span>• 
            <span onMouseEnter={()=>{setHoverTitle('Designer')}} onMouseLeave={()=>{setHoverTitle(null)}}> Designer </span>• 
            <span onMouseEnter={()=>{setHoverTitle('Artist')}} onMouseLeave={()=>{setHoverTitle(null)}}> Artist</span>
          </div>
          <div className='links'>
            <LinkIcon url={ShayelCV} text='CV' insite={true} fadeIcon={['Artist'].includes(hoverTitle)}/>
            <LinkIcon url='https://www.linkedin.com/in/shayelmag/' icon={faLinkedin} fadeIcon={['Artist'].includes(hoverTitle)} />
            <LinkIcon url='https://github.com/shay8el' icon={faGithub} fadeIcon={['Artist', 'Designer'].includes(hoverTitle)} />
            <LinkIcon url='https://www.facebook.com/s.magnezi/' icon={faFacebookF} fadeIcon={['Engineer'].includes(hoverTitle)} />
            <LinkIcon url='https://www.instagram.com/shayel/' icon={faInstagram} fadeIcon={['Engineer'].includes(hoverTitle)} />
            <LinkIcon url='https://twitter.com/shay8el' icon={faTwitter} fadeIcon={['Artist', 'Designer'].includes(hoverTitle)} />
            <LinkIcon url='https://www.behance.net/SM-d' icon={faBehance} fadeIcon={['Engineer'].includes(hoverTitle)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
